import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

import './custom.css'
import {Login} from "./components/Login";
import {BrowserRouter, Redirect, Switch} from "react-router-dom";
import {AdminPanel} from "./components/AdminPanel";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <div>
                    <Switch>
                        <Route path='/login' component={Login}/>
                        <Route path="/admin" component={AdminPanel} />
                        <Route path="/home" component={Home} />
                        <Redirect exact to={'/home/'}/>
                    </Switch>
            </div>
        );
    }
}