import React, {Component, useEffect, useState} from 'react';
import {NavMenu} from "./NavMenu";
import {
    Container,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
    Label,
    Input,
    Button,
    ModalBody,
    Modal, ModalHeader
} from "reactstrap";
import {useHistory} from "react-router-dom";
export const Login = ()=> {

    const [activeTab, setActiveTab] = useState('1');
    const [modal, setModal] = useState(false);
    const history = useHistory();
    useEffect(() => {
        const navigate = async () => {
            if(sessionStorage.getItem("Login")=="1"){
                history.push('/')
            }
        }

        // call the async function 
        navigate()

    }, [])
    const doLogin = ()=>{
        sessionStorage.setItem("Login","1");
        history.push("/home");
    }
    return (
        <div>
            <NavMenu showSearch={false}/>
            <Container className={"w-100 p-5"} style={{height: '100% !important', width: '100%'}}>
                <div className={"shadow-ylw p-3"}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink style={{cursor:"pointer"}} className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                ورود
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{cursor:"pointer"}} className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                ثبت نام
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className={"mt-3"}>
                                <Label>نام کاربری یا شماره‌تلفن</Label>
                                <Input placeholder={"نام‌کاربری"}/>
                            </div>

                            <div className={"mt-3"}>
                                <Label>رمزعبور</Label>
                                <Input placeholder={"رمزعبور"}/>
                            </div>
                            <Button onClick={doLogin} color={"dark"} className={"my-2 d-block w-100 text-center"}>ورود</Button>
                            <button onClick={()=>setModal(!modal)} className={"btn btn-link text-dark btn-block text-center mt-3"}>رمزعبورم را فراموش
                                کرده ام
                            </button>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className={"mt-3"}>
                                <Label>نام و نام‌خانوادگی</Label>
                                <Input placeholder={"نام و نام‌خانوادگی"}/>
                            </div>

                            <div className={"mt-3"}>
                                <Label>نام کاربری</Label>
                                <Input placeholder={"نام‌کاربری"}/>
                            </div>

                            <div className={"mt-3"}>
                                <Label>کدملی</Label>
                                <Input placeholder={"کدملی"}/>
                            </div>
                            
                            <div className={"mt-3"}>
                                <Label>شماره تلفن همراه</Label>
                                <Input placeholder={"شماره تلفن همراه"}/>
                            </div>

                            <div className={"mt-3"}>
                                <Label>ایمیل</Label>
                                <Input placeholder={"ایمیل"}/>
                            </div>

                            <div className={"mt-3"}>
                                <Label>رمزعبور</Label>
                                <Input placeholder={"رمزعبور"}/>
                            </div>
                            <Button color={"dark"} className={"my-2 d-block w-100 text-center"}>ثبت‌نام</Button>
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
            <Modal isOpen={modal}
                   toggle={()=>setModal(!modal)}
                   modalTransition={{timeout: 700}}>
                <ModalHeader>رمزعبور خود را فراموش کرده ام</ModalHeader>
                <ModalBody>
                    <div className={"mt-3"}>
                        <Label>ایمیل یا شماره‌تلفن همراه</Label>
                        <Input placeholder={""}/>
                    </div>
                    <Button color={"dark"} className={"my-2 d-block w-100 text-center"}>بازیابی رمزعبور</Button>
                </ModalBody>
            </Modal>
        </div>
    );
}