import React, {useEffect, useState} from "react";
import {Button, Container, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarker, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faInstagram, faInternetExplorer, faTelegram, faWhatsapp} from "@fortawesome/free-brands-svg-icons"
import Select from "react-select";
import {CoursesList} from "./CoursesList";
import {useHistory, useParams} from "react-router-dom";
import {AdminNavMenu} from "./AdminNavMenu";
import {AdminAcademyForm} from "./AdminAcademyForm";
import {useAlert} from "react-alert";

export const AdminAcademyEdit = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const {id} = useParams();
    const [academy , setAcademy] = useState({});
    const history = useHistory()
    const alert = useAlert()
    
    useEffect(()=>{
        fetch("/Academy/"+id).then(response=>response.json()).then(data=>
            setAcademy(data)).catch(()=>{});
    },[]);

    const update = (body) => {
        fetch("/Academy/" + id, {
            method: 'PUT', body: JSON.stringify(body), headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then(
            res => {
                if (res.status < 300)
                    history.replace('/admin')
                else {
                    var data = res.json().then(
                        data=> {
                            var errors = data.errors;
                            Object.keys(errors).map(err => errors[err].map(msg=>alert.error(msg)))
                        }
                    ).catch();

                }
            }).catch((err) => {
            console.log(err)
        });
    }
    
    return(
        <>
            <AdminNavMenu fixed={"top"} showIcon={true} showCreate={true}/>
            <AdminAcademyForm academy={academy} method={update}/>
        </>
    );
}