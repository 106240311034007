import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faClock,
    faGlobe,
    faGraduationCap,
    faMapMarker,
    faMoneyBill,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import {faCalendar} from "@fortawesome/free-solid-svg-icons/faCalendar";
import {Link, useHistory} from "react-router-dom";

export const CoursesList = (props) => {
    const history = useHistory();
    return (<div className="list-group">
        {(props.courses??[]).map((course) => <>
                    <span
                        className="shadow-ylw list-group-item bg-dark text-warning list-group-item bg-dark text-warning-action">
                        <div className="d-flex w-100 justify-content-between">
                            <Link to={"/home/course/"+course.id} className={"text-warning"}>
                                <h5 style={{cursor: "pointer"}}
                                    className="mb-1">{course.name}</h5>
                            </Link>
                        </div>
                        
                        <Link className={"text-warning"} to={"/home/academy/"+(props.academy?props.academy.id:course.academy?course.academy.id:"")}>
                                                    <span style={{cursor: "pointer"}} className="mb-1">
                            <FontAwesomeIcon icon={faGraduationCap}/> &nbsp;
                                                        {props.academy?props.academy.name:course.academy?course.academy.name:""}</span>
                        </Link>
                        
                        <p className="mb-2">{course.bio.substring(0,30)}</p>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={faCalendar}/> &nbsp; شروع:&nbsp;
                            {new Date(course.startTime).toLocaleDateString('fa-IR')}    
                        </div>
                        
                        <div className="mb-1">
                            <FontAwesomeIcon icon={faClock}/> &nbsp;  طول دوره:&nbsp;
                            {course.duration} ساعت</div>
                        
                                                <div className="mb-1">
                            <FontAwesomeIcon icon={faMoneyBill}/> &nbsp; هزینه: &nbsp;
                                                    {course.price} تومان</div>

                        {course.isOnline?<div className="mb-1">
                            <FontAwesomeIcon icon={faGlobe}/> &nbsp;
                            آنلاین</div>:""}
                        <div>
                        {course.isOpen?
                        <><FontAwesomeIcon icon={faCheck}/> &nbsp;
                            ثبت نام مهلت دارد</>:
                            <><FontAwesomeIcon icon={faTimes}/> &nbsp;
                                ثبت نام مهلت ندارد</>
                        }                            
                        </div>
                        
                                        <div className={"my-2"}>                            {(course.courseTags??[]).slice(0,7).map(t=>
                                            <span className="badge m-1 badge-warning">{t.name}</span>)}</div>
                    </span>
            <hr className="text-warning"/>
        </>)}
    </div>);
}