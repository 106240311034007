import React, {Component, useEffect, useState} from 'react';
import {
    Container,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
    Label,
    Input,
    Button,
    ModalBody,
    Modal, ModalHeader, FormGroup
} from "reactstrap";
import Select from "react-select";
import {useHistory} from "react-router-dom";
import querystring from 'query-string';

export const SearchBox = ()=> {

    const [activeTab, setActiveTab] = useState('1');
    const [academyTags , setAcademyTags] = useState([]);
    const [courseTags , setCourseTags] = useState([]);
    useEffect(()=> {
        fetch("/AcademyTag/").then(response => response.json()).then(data =>
            setAcademyTags(data.map(({
                                     name: stroke,
                                     ...rest
                                 }) => ({
                label: stroke,
                ...rest
            })))).catch(() => {
        });

        fetch("/CourseTag/").then(response => response.json()).then(data =>
            setCourseTags(data.map(({
                                         name: stroke,
                                         ...rest
                                     }) => ({
                label: stroke,
                ...rest
            })))).catch(() => {
        });
        
        setAcademyData(querystring.parse(window.location.search))
        setCourseData(querystring.parse(window.location.search))
    },[]);
    
    const history = useHistory();
    const [academyData , setAcademyData] = useState({});
    const [courseData , setCourseData] = useState({});
    return (
        <>
            <Nav className={"justify-content-center"} tabs>
                <NavItem>
                    <NavLink style={{cursor:"pointer"}} className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                        آموزشگاه
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink style={{cursor:"pointer"}} className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                        دوره
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className={"max50height"} activeTab={activeTab}>
                <TabPane tabId="1">
                    <div className={"mt-3"}>
                        <Label>نام</Label>
                        <Input value={academyData.name} onChange={(e)=>setAcademyData(
                            {...academyData, name:e.target.value}
                        )} placeholder={"نام"}/>
                    </div>

                    <div className={"mt-3"}>
                        <Label>حداکثر فاصله از شما</Label>
                        <Input value={academyData.distance} onChange={(e)=>setAcademyData(
                            {...academyData, distance:e.target.value}
                        )} type={"number"} min={0} placeholder={"10 کیلومتر"}/>
                    </div>

                    <div className={"mt-3"}>
                        <Label>برچسب</Label>
                        <Select isMulti placeholder={"لطفا گزینه هایی را انتخاب کنید"}  className="basic-multi-select"
                                classNamePrefix="select"     options={academyTags} />
                    </div>
                    <div className={"mt-3"}>
                        <Label>برچسب دوره‌ها</Label>
                        <Select isMulti placeholder={"لطفا گزینه هایی را انتخاب کنید"}  className="basic-multi-select"
                                classNamePrefix="select" options={courseTags} />
                    </div>
                    <Button onClick={()=>{
                        history.replace("/home/academies?"+querystring.stringify(academyData))
                    }} color={"warning"} className={"my-2 d-block w-100 text-center"}>جستجو</Button>
                </TabPane>
                <TabPane tabId="2">
                    <div className={"mt-3"}>
                        <Label>نام</Label>
                        <Input value={courseData.name} onChange={(e)=>setCourseData(
                            {...courseData, name:e.target.value}
                        )} placeholder={"نام"}/>
                    </div>

                    <div className={"mt-3"}>
                        <Label>نام آموزشگاه</Label>
                        <Input  value={courseData.academyName} onChange={(e)=>setCourseData(
                            {...courseData, academyName:e.target.value}
                        )} placeholder={"نام آموزشگاه"}/>
                    </div>

                    <div className={"mt-3"}>
                        <Label>برچسب</Label>
                        <Select isMulti placeholder={"لطفا گزینه هایی را انتخاب کنید"}  className="basic-multi-select"
                                classNamePrefix="select"     options={courseTags} />
                    </div>
                    <div className={"mt-3"}>
                        <Label>برچسب آموزشگاه</Label>
                        <Select isMulti placeholder={"لطفا گزینه هایی را انتخاب کنید"}  className="basic-multi-select"
                                classNamePrefix="select"     options={academyTags} />
                    </div>

                    <div className={"mt-3 row"}>
                        <div className={"col-6"}>
                            <Label>حداقل قیمت</Label>
                            <Input  value={courseData.minPrice} onChange={(e)=>setCourseData(
                                {...courseData, minPrice:e.target.value}
                            )} type={"number"}/>
                        </div>
                        <div className={"col-6"}>
                            <Label>حداکثر قیمت</Label>
                            <Input value={courseData.maxPrice} onChange={(e)=>setCourseData(
                                {...courseData, maxPrice:e.target.value}
                            )}  type={"number"}/>
                        </div>
                    </div>

                    <div className={"mt-3 row"}>
                        <div className={"col-6"}>
                            <Label>حداقل مدت زمان</Label>
                            <Input  value={courseData.minDuration} onChange={(e)=>setCourseData(
                                {...courseData, minDuration:e.target.value}
                            )}  type={"number"}/>
                        </div>
                        <div className={"col-6"}>
                            <Label>حداکثر مدت زمان</Label>
                            <Input value={courseData.maxDuration} onChange={(e)=>setCourseData(
                                {...courseData, maxDuration:e.target.value}
                            )}  type={"number"}/>
                        </div>
                    </div>

                    <div className={"mt-3"}>
                        <Label check>
                            آنلاین
                        </Label>
                        <FormGroup
                            check
                            inline
                        >
                            <Input type="checkbox"  checked={courseData.isOnline} onChange={(e)=>setCourseData(
                                {...courseData, isOnline:e.target.checked}
                            )}  />
                        </FormGroup>
                    </div>
                    <Button onClick={()=>history.replace("/home/courses?"+querystring.stringify(courseData))} color={"warning"} className={"my-2 d-block w-100 text-center"}>جستجو</Button>
                </TabPane>
            </TabContent></>
    );
}