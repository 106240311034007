import React, { Component } from 'react';
import {
  Button,
  Collapse,
  Container,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faGraduationCap, faTachometerAlt, faSearch, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {SearchBox} from "./SearchBox";

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleAbout = this.toggleAbout.bind(this);
    this.state = {
      collapsed: true,
      aboutCollapsed: true,
      showIcon: false,
      fixed: null
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
      aboutCollapsed: true
    });
  }

  toggleAbout() {
    this.setState({
      aboutCollapsed: !this.state.aboutCollapsed,
      collapsed: true
    });
  }

  render() {
    return (
          <Navbar fixed={this.props.fixed} className="bg-dark navbar-toggleable ng-white border-bottom box-shadow" light>
            <Container className={"justify-content-around"}>
              {
                this.props.showIcon ?
                    <div>

                      <div onClick={this.toggleAbout} className="btn mr-2">
                        <FontAwesomeIcon icon={faExclamationCircle} color={"#ffc107"} size="lg"/>
                      </div>
                      <Link to={'/admin'} className="btn mr-2">
                        <FontAwesomeIcon icon={faTachometerAlt} color={"#ffc107"} size="lg"/>
                      </Link>
                    </div> : null
              }
              <h3><NavLink tag={Link} className={"text-warning"} to="/home">
                <FontAwesomeIcon icon={faGraduationCap} color={"#ffc107"} size="lg"/> فن‌جو</NavLink></h3>
              {
                this.props.showIcon ?<div onClick={this.toggleNavbar} className="btn mr-2">
                        <FontAwesomeIcon icon={faSearch} color={"#ffc107"} size="lg"/>
                      </div>: null
              }
              <Collapse className="text-warning pb-2" isOpen={!this.state.collapsed} navbar>
                {/*              <ul className="navbar-nav flex-grow text-right">
                <NavItem>
                  <NavLink tag={Link} className="text-warning" to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-warning" to="/counter">Counter</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-warning" to="/fetch-data">Fetch data</NavLink>
                </NavItem>
              </ul>*/}
                <SearchBox/> 
              </Collapse>

              <Collapse className="text-warning" isOpen={!this.state.aboutCollapsed} navbar>
                طراحی شده و توسعه یافته در مجتمع امیران &copy;
              </Collapse>
            </Container>
          </Navbar>
    );
  }
}
