import React, {Component, useEffect, useState} from 'react';
import {
    Container,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
    Label,
    Input,
    Button,
    ModalBody,
    Modal, ModalHeader, FormGroup, Collapse
} from "reactstrap";
import Select from "react-select";
import {AcademiesList} from "./AcademiesList";
import {AcademyInfo} from "./AcademyInfo";
import {CoursesList} from "./CoursesList";
import {CourseInfo} from "./CourseInfo";
import {Route} from "react-router-dom";
import {CoursesListNav} from "./CoursesListNav";
export const DataBar = ()=> {

    const [activeTab, setActiveTab] = useState('3');

    return (
        <div className={"fixed-bottom"}>
            <switch>
                <Route path={"/home/academies"} component={AcademiesList}/>
                <Route path={"/home/academy/:id"} component={AcademyInfo}/>
                <Route path={"/home/courses"} component={CoursesListNav}/>
                <Route path={"/home/course/:id"} component={CourseInfo}/>
            </switch>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    
                </TabPane>
                <TabPane tabId="2">
                    <AcademyInfo setActiveTab={setActiveTab}/>
                </TabPane>
                <TabPane tabId="3">

                </TabPane>
                <TabPane tabId="4">
                    <CourseInfo setActiveTab={setActiveTab}/>
                </TabPane>
            </TabContent>
        </div>
    );
}