import React, {Component, useEffect, useState} from 'react';
import {Container} from "reactstrap";
import {NavMenu} from "./NavMenu";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {DataBar} from "./DataBar";
import { Map, Marker } from "pigeon-maps";
import {AdminNavMenu} from "./AdminNavMenu";
import {AdminAcademiesList} from "./AdminAcademiesList";
import {Login} from "./Login";
import {Home} from "./Home";
import {CreateAcademy} from "./CreateAcademy";
import {AdminAcademyEdit} from "./AdminAcademyEdit";
export const AdminPanel = ()=> {
    const history = useHistory()

    useEffect(() => {
        const navigate = async () => {
            if(sessionStorage.getItem("Login")!="1"){
                history.push('/login')
            }
        }

        // call the async function 
        navigate()

    }, [window.location])
    return (
        <div>
            <Switch>
                <Route path="/admin/academy/create" component={CreateAcademy} />
                <Route path="/admin/academy/:id" component={AdminAcademyEdit} />
                <Route path='/admin' component={AdminAcademiesList}/>
            </Switch>
        </div>
    );
}