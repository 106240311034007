import React, {useEffect, useState} from "react";
import {Button, Collapse, Container, Input, Label} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    fa,
    faCross,
    faEdit,
    faMap,
    faMapMarker,
    faTimes,
    faTrash,
    faCircle, faCheck, faSearch, faPen
} from '@fortawesome/free-solid-svg-icons';
import {Link, useHistory} from "react-router-dom";
import {SearchBox} from "./SearchBox";
import {AdminNavMenu} from "./AdminNavMenu";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useAlert} from "react-alert";
import Select from "react-select";
import querystring from "query-string"; // Import css

export const AdminAcademiesList = (props) => {
    const history = useHistory();
    const [Academies, setAcademies] = useState([]);
    const [Academy, setAcademy] = useState(querystring.parse(window.location.search) ?? {status: null, name: ""});
    const [searchOpened, setSearchOpened] = useState(false);

    useEffect(() => {
        fetch("/Academy/my" + window.location.search).then(response => response.json()).then(data =>
            setAcademies(data)).catch(() => {
        });
        console.log("1")
    }, [window.location.search]);

    const alert = useAlert();
    return (
        <>

            <AdminNavMenu fixed={"top"} showIcon={true} showCreate={true}/>
            <Container className={searchOpened ? 'bg-dark shadow-ylw rounded-lg text-warning p-2' : 'text-warning'}>
                <div className={'text-center'}>
                    <span className={!searchOpened ? 'text-center bg-dark p-2 rounded-lg shadow-ylw' : ''}
                          onClick={() => setSearchOpened(!searchOpened)}>
                        
                    {searchOpened ? <FontAwesomeIcon size={'lg'} icon={faTimes}/> :
                        <FontAwesomeIcon size={'lg'} icon={faSearch}/>}
                    </span>
                </div>
                <Collapse isOpen={searchOpened}>
                    <div className={"mt-3"}>
                        <Label>نام</Label>
                        <Input value={Academy.name} onChange={(e) => setAcademy({...Academy, name: e.target.value})}
                               placeholder={"نام"}/>
                    </div>

                    <div className={"mt-3"}>
                        <Label>برچسب</Label>
                        <Select isMulti placeholder={"لطفا گزینه هایی را انتخاب کنید"} className="basic-multi-select"
                                classNamePrefix="select"/>
                    </div>
                    <div className={"mt-3"}>
                        <Label>وضعیت</Label>
                        <select value={Academy.status}
                                onChange={(e) => setAcademy({...Academy, status: e.target.value})}
                                placeholder={"لطفا گزینه هایی را انتخاب کنید"} className="form-control">
                            <option value={null}></option>
                            <option value={1}>تایید شده</option>
                            <option value={0}>در حال بررسی</option>
                            <option value={-1}>رد شده</option>
                        </select>
                    </div>
                    <Button onClick={() => {
                        history.replace("/admin?" + querystring.stringify(Academy))
                    }} color={"warning"} className={"my-2 d-block w-100 text-center"}>جستجو</Button>
                </Collapse>
            </Container>
            <Container className={""}>
                <div className="list-group p-4" style={{borderTopRightRadius: "25px"}}>
                    {(Academies).map((academy) => <>
                    <span
                        className="shadow-ylw list-group-item bg-dark text-warning list-group-item bg-dark text-warning-action">
                        <div className="d-flex w-100 justify-content-between">
                            <Link className={"text-warning"} to={"/home/academy/" + academy.id}><h5
                                style={{cursor: "pointer"}} className="mb-1">{academy.name}</h5></Link>
                            <small>
                                {academy.status == 0 ?
                                    <span>در درست بررسی <FontAwesomeIcon icon={faCircle}/></span> : ""}
                                {academy.status == 1 ? <span>تایید شده  <FontAwesomeIcon icon={faCheck}/></span> : ""}
                                {academy.status == -1 ? <span style={{cursor: 'pointer'}}
                                                              onClick={() => alert.error(academy.statusMessage)}>رد شده <FontAwesomeIcon
                                    icon={faTimes}/></span> : ""}
                            </small>
                        </div>
                        <p className="mb-2">{academy.bio.substring(0, 40)}...</p>
                        <p className="mb-1">
                            <FontAwesomeIcon icon={faMapMarker}/> &nbsp;
                            {academy.location}
                        </p>
                        <div>
                            {(academy.academyTags ?? []).slice(0, 7).map(t =>
                                <span className="badge m-1 badge-warning">{t.name}</span>)}
                        </div>
                                <div>
                                    <Link style={{background:'linear-gradient(90deg, rgba(220,53,69,1) 40%, rgba(255,193,7,1) 60%)'}} to={'/admin/academy/' + academy.id}
                                          className={'btn btn-block border-dark text-center btn-warning'}>
                                    <FontAwesomeIcon icon={faPen}/> &nbsp;
                                        ویرایش دوره‌ها
                                </Link>       
                                </div>
                                                 <div className={'row mt-1'}>
                            <div className={'col-sm-6 col-12 mb-1'}>
                                <Link to={'/admin/academy/' + academy.id}
                                      className={'btn btn-block text-center btn-warning'}>
                                    <FontAwesomeIcon icon={faEdit}/> &nbsp;
                                    ویرایش آموزشگاه
                                </Link>
                            </div>
                            <div className={'col-sm-6 col-12 mb-1'}>
                                <button onClick={() => {
                                    confirmAlert({
                                        title: 'اخطار',
                                        message: 'آیا از این عملیات غیر قابل بازگشت مطمئن هستید؟',
                                        buttons: [
                                            {
                                                label: 'بله',
                                                onClick: () => {
                                                    fetch("/Academy/" + academy.id, {method: 'DELETE'}).then(() => fetch("/Academy/search/").then(response => response.json()).then(data =>
                                                        setAcademies(data)).catch(() => {
                                                    })).catch(() => {
                                                    });
                                                    console.log("ABCD");
                                                }
                                            },
                                            {
                                                label: 'خیر'
                                            }
                                        ]
                                    })
                                }} className={'btn btn-block text-center btn-danger'}>
                                    <FontAwesomeIcon icon={faTrash}/> &nbsp;
                                    حذف آموزشگاه
                                </button>
                            </div>
                        </div>
                    </span>
                        <hr className="text-warning"/>
                    </>)}
                </div>
            </Container>
        </>
    );
}