import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fa, faCross, faMap, faMapMarker, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Link, useHistory} from "react-router-dom";


export const AcademiesList = (props) => {
    const history = useHistory();
    const [academies,setAcademies] = useState([]);
    useEffect(()=>{
        fetch("/Academy/search"+window.location.search).then(response=>response.json()).then(data=>
        setAcademies(data)).catch(()=>{});
        console.log("1")
    },[window.location.search]);
    return (
        <Container className={""}>
            <div className="list-group bg-dark max50height p-4" style={{borderTopRightRadius:"25px"}}>
                {academies.map((academy) =><>
                    <span
                       className="shadow-ylw list-group-item bg-dark text-warning list-group-item bg-dark text-warning-action">
                        <div className="d-flex w-100 justify-content-between">
                            <Link className={"text-warning"} to={"/home/academy/"+academy.id}><h5 style={{cursor:"pointer"}} className="mb-1">{academy.name}</h5></Link>
                            <small>۱۰ کلیومتر</small>
                        </div>
                        <p className="mb-2">{academy.bio.substring(0,40)}...</p>
                        <p className="mb-1">
                            <FontAwesomeIcon icon={faMapMarker}/> &nbsp;
                            {academy.location}
                        </p>
                        <div>
                            {(academy.academyTags??[]).slice(0,7).map(t=>
                                <span className="badge m-1 badge-warning">{t.name}</span>)}
                        </div>
                    </span>
                <hr className="text-warning"/></>)}
            </div>
        </Container>
    );
}