import React, {useEffect, useState} from "react";
import {Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faClock,
    faGlobe,
    faGraduationCap,
    faMapMarker,
    faMoneyBill,
    faPhone, faTimes
} from "@fortawesome/free-solid-svg-icons";
import {faInstagram, faInternetExplorer, faTelegram, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {CoursesList} from "./CoursesList";
import {faCalendar} from "@fortawesome/free-solid-svg-icons/faCalendar";
import {Link, useHistory, useParams} from "react-router-dom";
import {useAlert} from "react-alert";
export const CourseInfo = (props) => {
    const {id} = useParams();
    const [course,setCourse] = useState({});
    const history = useHistory();
    const alert = useAlert();
    
    useEffect(()=>{
        fetch("/Course/"+id).then(response=>response.json()).then(data=>{
            if(data.status>=300 && id!=null){
                console.log(data)
                history.replace('/home')
                alert.error("دریافت اطلاعات دوره موفقیت آمیز نبود.")
            }
            else {
                setCourse(data)
            }
        }).catch(()=>{});
    },[]);
    return(
        <Container>
            <div className={"max50height bg-dark text-warning p-5"}  style={{borderTopRightRadius:"25px"}}>
                <div className="d-flex w-100 justify-content-between">
                    <h5 style={{cursor: "pointer"}} className="mb-1">{course.name}</h5>
                </div>

                <Link className={"text-warning"} to={"/home/academy/"+(course.academy ? course.academy.id : "")}>
                                                    <span style={{cursor: "pointer"}} className="mb-1">
                            <FontAwesomeIcon icon={faGraduationCap}/> &nbsp;
                                                        {course.academy ? course.academy.name : ""}</span>
                </Link>

                <p dangerouslySetInnerHTML={{__html:course.bio ? course.bio.replace("\n","<br>") : ""}}>
                </p>
                <div className="mb-1">
                    <FontAwesomeIcon icon={faCalendar}/> &nbsp; شروع:&nbsp;
                    {new Date(course.startTime).toLocaleDateString('fa-IR')}
                </div>

                <div className="mb-1">
                    <FontAwesomeIcon icon={faClock}/> &nbsp;  طول دوره:&nbsp;
                    {course.duration} ساعت</div>

                <div className="mb-1">
                    <FontAwesomeIcon icon={faMoneyBill}/> &nbsp; هزینه: &nbsp;
                    {course.price} تومان</div>

                {course.isOnline?<div className="mb-1">
                    <FontAwesomeIcon icon={faGlobe}/> &nbsp;
                    آنلاین</div>:""}
                <div>
                    {course.isOpen?
                        <><FontAwesomeIcon icon={faCheck}/> &nbsp;
                            ثبت نام مهلت دارد</>:
                        <><FontAwesomeIcon icon={faTimes}/> &nbsp;
                            ثبت نام مهلت ندارد</>
                    }
                </div>

                <div className={"my-2"}>                            {(course.courseTags??[]).map(t=>
                    <span className="badge m-1 badge-warning">{t.name}</span>)}</div> 
            </div>
        </Container>
    );
}