import React, {useEffect, useState} from "react";
import {Container, Input, Label} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fa, faCross, faEdit, faMap, faMapMarker, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Link, useHistory} from "react-router-dom";
import {SearchBox} from "./SearchBox";
import {AdminNavMenu} from "./AdminNavMenu";
import Select from "react-select";
import {AdminAcademiesList} from "./AdminAcademiesList";
import {AdminAcademyForm} from "./AdminAcademyForm";
import {useAlert} from "react-alert";


export const CreateAcademy = (props) => {
    const history = useHistory();
    const alert = useAlert();
    
    const create = (body) => {
        fetch("/Academy/create", {
            method: 'POST', body: JSON.stringify(body), headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then(
            res => {
                if (res.status < 300)
                    history.replace('/admin')
                else {
                    var data = res.json().then(
                        data=> {
                            var errors = data.errors;
                            Object.keys(errors).map(err => errors[err].map(msg=>alert.error(msg)))
                        }
                    ).catch();

                }
            }).catch((err) => {
            console.log(err)
        });
    }
    return (
        <>
            <AdminNavMenu fixed={"top"} showIcon={true} showCreate={false}/>
                <AdminAcademyForm method={create} academy={{}}/>
        </>
    );
}