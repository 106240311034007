import React, {useEffect, useState} from "react";
import {Container, Input, Label} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fa, faCross, faEdit, faMap, faMapMarker, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Link, useHistory} from "react-router-dom";
import {SearchBox} from "./SearchBox";
import {AdminNavMenu} from "./AdminNavMenu";
import Select from "react-select";


export const AdminAcademyForm = (props) => {
    const history = useHistory();

    const [academy , setAcademy] = useState(props.academy);
    useEffect(()=>{setAcademy(props.academy??{name:""})},[props.academy])
    return (
        <>
            <Container className={"bg-dark my-3 p-3 text-warning shadow-ylw rounded-lg"}>
                <div className={''}>
                    <Label>نام</Label>
                    <Input value={academy.name} onChange={(e)=>{
                        setAcademy({...academy , name:e.target.value})
                    }} placeholder={"نام آموزشگاه"}/>
                </div>


                <div className={"mt-3"}>
                    <Label >شماره مجوز</Label>
                    <Input  value={academy.licenseCode} onChange={(e)=>{
                        setAcademy({...academy , licenseCode:e.target.value})
                    }} placeholder={"شماره مجوز آموزشگاه"}/>
                </div>

                <div className={"mt-3"}>
                    <Label>آدرس</Label>
                    <Input value={academy.location} onChange={(e)=>{
                        setAcademy({...academy , location:e.target.value})
                    }}  placeholder={"آدرس آموزشگاه"}/>
                </div>

                <div className={"mt-3"}>
                    <Label>درباره</Label>
                    <textarea  onChange={(e)=>{
                        setAcademy({...academy , bio:e.target.value})
                    }} className={'form-control'} placeholder={"درباره آموزشگاه"} value={academy.bio}/>
                </div>

                <div className={"mt-3"}>
                    <Label>وب‌سایت</Label>
                    <Input value={academy.website} onChange={(e)=>{
                        setAcademy({...academy , website:e.target.value})
                    }}   placeholder={"وب‌سایت آموزشگاه"}/>
                </div>

                <div className={"mt-3 row"}>
                    <div className={'col-6'}>
                        <Label>تلفن</Label>
                        <Input value={academy.phoneNumber} onChange={(e)=>{
                            setAcademy({...academy , phoneNumber:e.target.value})
                        }}  placeholder={"تلفن آموزشگاه"}/>
                    </div>
                    <div className={'col-6'}>
                        <Label>واتساپ</Label>
                        <Input value={academy.whatsapp} onChange={(e)=>{
                            setAcademy({...academy , whatsapp:e.target.value})
                        }}   placeholder={"واتساپ آموزشگاه"}/>
                    </div>
                </div>

                <div className={"mt-3 row"}>
                    <div className={'col-6'}>
                        <Label>اینستاگرام</Label>
                        <Input value={academy.instagram} onChange={(e)=>{
                            setAcademy({...academy , instagram:e.target.value})
                        }}   placeholder={"اینستاگرام آموزشگاه"}/>
                    </div>
                    <div className={'col-6'}>
                        <Label>تلگرام</Label>
                        <Input value={academy.telegram} onChange={(e)=>{
                            setAcademy({...academy , telegram:e.target.value})
                        }}  placeholder={"تلگرام آموزشگاه"}/>
                    </div>
                </div>
                <button onClick={props.academy ? ()=>props.method(academy) : ()=>{console.log("HI")}} className={'btn btn-warning btn-block text-center mt-2'}>ثبت در سامانه</button>
            </Container>
        </>
    );
}