import React, {Component, useEffect} from 'react';
import {Container} from "reactstrap";
import {NavMenu} from "./NavMenu";
import {useHistory} from "react-router-dom";
import {DataBar} from "./DataBar";
import { Map, Marker } from "pigeon-maps";
export const Home = ()=> {
    const history = useHistory()

    useEffect(() => {
        const navigate = async () => {
            if(sessionStorage.getItem("Login")!="1"){
                history.push('/login')
            }
        }

        // call the async function 
        navigate()

    }, [])
    return (
        <div>
            <NavMenu fixed={"top"} showIcon={true}/>
            <div style={{direction:'ltr !important' , textAlign:'left'}}>
                <Map height={'100vh'} defaultCenter={[50.879, 4.6997]} defaultZoom={11}>
                    <Marker
                        width={50}
                        anchor={[50.879, 4.6997]}
                        color={'blue'}
                    />
                </Map>
            </div>
            <DataBar/>
        </div>
    );
}