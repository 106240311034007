import React, {useEffect, useState} from "react";
import {CoursesList} from "./CoursesList";
import {Container} from "reactstrap";
export const CoursesListNav = () => {
    const [courses,setCourses] = useState([]);
    useEffect(()=>{
        fetch("/Course/search"+window.location.search).then(response=>response.json()).then(data=>
            setCourses(data)).catch(()=>{});
    },[window.location.search]);
    return (
        <Container>
            <div className={"max50height bg-dark text-warning p-5"}  style={{borderTopRightRadius:"25px"}}>
                <CoursesList courses={courses}/>
            </div>
        </Container>
    );
}