import React, { Component } from 'react';
import {
    Button,
    Collapse,
    Container,
    Input,
    Label,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGraduationCap, faSearch, faExclamationCircle, faPlus} from '@fortawesome/free-solid-svg-icons';
import {SearchBox} from "./SearchBox";

export class AdminNavMenu extends Component {
    static displayName = AdminNavMenu.name;

    constructor (props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render () {
        return (
            <header className={'text-warning'}>
                <Navbar className="bg-dark text-warning navbar-expand navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} className={"text-warning"} to="/admin">
                            <FontAwesomeIcon icon={faGraduationCap} color={"#ffc107"} size="lg"/> فن‌جو</NavbarBrand>
                            <NavLink tag={Link} className="m-2 btn btn-warning text-center" to="/">
                                <span className={'d-none d-sm-inline'}>پنل کار آموز&nbsp;</span>
                                <FontAwesomeIcon icon={faGraduationCap}/>
                            </NavLink>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-inline-flex flex-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {this.props.showCreate?                                 <NavItem>
                                    <NavLink tag={Link} className="btn btn-warning" to="/admin/academy/create">
                                        <span className={'d-none d-sm-inline'}>ثبت آموزشگاه ایجاد&nbsp;</span>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </NavLink>
                                </NavItem> : ""}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
