import React, {useEffect, useState} from "react";
import {Button, Container, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarker, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faInstagram, faInternetExplorer, faTelegram, faWhatsapp} from "@fortawesome/free-brands-svg-icons"
import Select from "react-select";
import {CoursesList} from "./CoursesList";
import {useHistory, useParams} from "react-router-dom";
import {useAlert} from "react-alert";

export const AcademyInfo = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const {id} = useParams();
    const [academy, setAcademy] = useState({});
    const alert = useAlert();
    const history = useHistory();
    
    useEffect(() => {
        fetch("/Academy/" + id).then(response => response.json())
            .then(data =>
            {
                if(data.status>=300 && id!=null){
                    console.log(data)
                    history.replace('/home')
                    alert.error("دریافت اطلاعات آموزشگاه موفقیت آمیز نبود.")
                }
                else {
                    setAcademy(data)
                }
            }).catch(() => {
        });
    }, []);

    return (
        <Container>
            <div className={"max50height bg-dark text-warning p-3"} style={{borderTopRightRadius: "25px"}}>
                <Nav className={"justify-content-center"} tabs>
                    <NavItem>
                        <NavLink style={{cursor: "pointer"}} className={activeTab == '1' ? 'active' : ''}
                                 onClick={() => setActiveTab('1')}>
                            آموزشگاه
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{cursor: "pointer"}} className={activeTab == '2' ? 'active' : ''}
                                 onClick={() => setActiveTab('2')}>
                            دوره
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent className={"m-4 p-4"} activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{academy.name}</h5>
                            <small>۱۰ کلیومتر</small>
                        </div>
                        <p dangerouslySetInnerHTML={{__html: academy.bio ? academy.bio.replace("\n", "<br>") : ""}}>
                        </p>
                        <div className={"my-2"}>                            {(academy.academyTags ?? []).map(t =>
                            <span className="badge m-1 badge-warning">{t.name}</span>)}</div>

                        {academy.phoneNumber?
                            <p>
                                <a className={"text-warning"} href={"tel://" + academy.phoneNumber}>
                                    <FontAwesomeIcon icon={faPhone}/> &nbsp;
                                    {academy.phoneNumber}
                                </a>
                            </p>:<></>}
                        {academy.whatsapp?
                            <p>
                                <a className={"text-warning"} href={"https://wa.me/" + academy.whatsapp}>
                                    <FontAwesomeIcon icon={faWhatsapp}/> &nbsp;
                                    {academy.whatsapp}
                                </a>
                            </p>:<></>}

                        {academy.telegram?
                            <p>
                                <a className={"text-warning"} href={"https://t.me/" + academy.telegram}>
                                    <FontAwesomeIcon icon={faTelegram}/> &nbsp;
                                    {academy.telegram}
                                </a>
                            </p>:<></>}

                        {academy.instagram?
                            <p>
                                <a className={"text-warning"} href={"https://instagram.com/" + academy.instagram}>
                                    <FontAwesomeIcon icon={faInstagram}/> &nbsp;
                                    {academy.instagram}
                                </a>
                            </p>:<></>}
                        

                        {academy.website?
                            <p>
                                <a className={"text-warning"} href={"http://" + academy.website}>
                                    <FontAwesomeIcon icon={faInternetExplorer}/> &nbsp;
                                    {academy.website}
                                </a>
                            </p>:<></>}

                        {academy.location?
                            <p className={"my-2"}>
                                <FontAwesomeIcon icon={faMapMarker}/> &nbsp;
                                {academy.location}
                            </p>:<></>}
                    </TabPane>
                    <TabPane tabId="2">
                        <CoursesList courses={academy.courses} academy={academy} setActiveTab={props.setActiveTab}/>
                    </TabPane>
                </TabContent></div>
        </Container>
    );
}